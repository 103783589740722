import React, { useState, useEffect } from 'react'
import cn from 'classnames'
import isEmail from 'validator/lib/isEmail'
import Textarea from 'react-textarea-autosize'

import styles from './call-back.module.scss'
import SiteLayout from '../layout/SiteLayout'
import SEO from '../components/SEO'
import Section from '../components/Section'
import { Title, TitleContainer, TitleIntro } from '../components/Title'
import { Intro, IntroTitle, IntroText } from '../components/Intro'
import usePublicApi from '../hooks/usePublicApi'
import _ from 'lodash'

const CallBack = () => {
  const [name, setName] = useState('')
  const [nameErr, setNameErr] = useState(null)
  const [email, setEmail] = useState('')
  const [emailErr, setEmailErr] = useState(null)
  const [phone, setPhone] = useState('')
  const [phoneErr, setPhoneErr] = useState(null)
  const [time, setTime] = useState('')
  const [timeErr, setTimeErr] = useState(null)
  const [message, setMessage] = useState('')
  // const [marketing, setMarketing] = useState(false)
  const [formValid, setFormValid] = useState(false)
  const [showErrors, setShowErrors] = useState(false)

  const [{ isLoading, success, error }, { sendRequest }] = usePublicApi()

  useEffect(() => {
    setNameErr(name === '')
    setEmailErr(!isEmail(email))
    setPhoneErr(phone === '')
    setTimeErr(time === '')

    if (nameErr || emailErr || phoneErr || timeErr) {
      setFormValid(false)
    } else {
      setFormValid(true)
    }
  }, [name, nameErr, email, emailErr, phone, phoneErr, time, timeErr])

  const handleSubmit = () => {
    if (formValid) {
      sendRequest({
        method: 'post',
        url: '/marketing/call-back',
        data: { name, email, phone, time, message },
      })
    } else {
      setShowErrors(true)
    }
  }

  if (success) {
    return (
      <SiteLayout header="light">
        <SEO title="Request a call back" />
        <TitleContainer>
          <Title>Request a call back</Title>
          <TitleIntro>
            Complete and submit the form below and our friendly team will be
            call you at your preferred time.
          </TitleIntro>
        </TitleContainer>
        <Section color="gray">
          <div className={styles.success}>
            <Intro>
              <IntroTitle>Request received!</IntroTitle>
              <IntroText>
                We have received your request for a call. A member of our team
                will contact you shortly to answer any questions you may have.
              </IntroText>
            </Intro>
            <div className={styles.summary}>
              <h4>Here's a summary of what you sent us</h4>
              <div className={styles.details}>
                <p>
                  Your name: <strong>{name}</strong>
                </p>
                <p>
                  Your email: <strong className={styles.break}>{email}</strong>
                </p>
                <p>
                  Your phone: <strong>{phone}</strong>
                </p>
                <p>
                  Best time to call: <strong>{time}</strong>
                </p>

                {message !== '' && <p>Message: {message}</p>}
              </div>
            </div>
          </div>
        </Section>
      </SiteLayout>
    )
  }

  return (
    <SiteLayout header="light">
      <SEO title="Request a call back" />
      <TitleContainer>
        <Title>Request a call back</Title>
        <TitleIntro>
          Complete and submit the form below and our friendly team will be call
          you at your preferred time.
        </TitleIntro>
      </TitleContainer>
      <Section color="gray">
        <Intro>
          <IntroTitle>Complete the form below</IntroTitle>
        </Intro>
        <div className={styles.form}>
          <div className={styles.split}>
            <div
              className={cn(styles.inputRequired, {
                [styles.error]: nameErr && showErrors,
              })}
            >
              <input
                disabled={isLoading}
                id="name"
                onChange={e => setName(e.target.value)}
                placeholder="Your name"
                type="text"
                value={name}
              />
              <span className={styles.errorMsg}>Please enter your name.</span>
            </div>
            <div
              className={cn(styles.inputRequired, {
                [styles.error]: emailErr && showErrors,
              })}
            >
              <input
                disabled={isLoading}
                id="email"
                onChange={e => setEmail(e.target.value)}
                placeholder="Your email"
                type="text"
                value={email}
              />
              <span className={styles.errorMsg}>
                Please enter a valid email address.
              </span>
            </div>
          </div>
          <div className={styles.split}>
            <div
              className={cn(styles.inputRequired, {
                [styles.error]: phoneErr && showErrors,
              })}
            >
              <input
                disabled={isLoading}
                id="phone"
                onChange={e => setPhone(e.target.value)}
                placeholder="Your phone"
                type="text"
                value={phone}
              />
              <span className={styles.errorMsg}>
                Please enter your phone number.
              </span>
            </div>
            <div
              className={cn(styles.inputRequired, {
                [styles.error]: timeErr && showErrors,
              })}
            >
              <input
                disabled={isLoading}
                id="time"
                onChange={e => setTime(e.target.value)}
                placeholder="Best time to call"
                type="text"
                value={time}
              />
              <span className={styles.errorMsg}>
                Please enter the best time to call.
              </span>
            </div>
          </div>
          <div>
            <Textarea
              disabled={isLoading}
              id="message"
              minRows={5}
              placeholder="Enter your message (optional)..."
              onChange={e => setMessage(e.target.value)}
              value={message}
            />
          </div>
          <div className={styles.btnContainer}>
            <button
              className="btn btn-primary"
              disabled={isLoading}
              onClick={handleSubmit}
              type="button"
            >
              {isLoading
                ? 'Sending...'
                : error
                ? 'Try again'
                : 'Send my package and quote'}
            </button>
          </div>
          {error && (
            <div className={styles.error}>
              <p className="mb-0">
                There's was a problem sending your package. Please try again.
              </p>
              <small>
                Error code: {_.get(error, 'response.status') || '900'}
              </small>
            </div>
          )}
        </div>
      </Section>
    </SiteLayout>
  )
}

export default CallBack

/*
<div
  className={cn(styles.inputRequired, {
    [styles.error]: subjectErr && showErrors,
  })}
>
  <input
    disabled={isLoading}
    id="subject"
    onChange={e => setSubject(e.target.value)}
    placeholder="Subject"
    type="text"
    value={subject}
  />
  <span className={styles.errorMsg}>Please enter a message subject.</span>
</div>
<div
  className={cn(styles.inputRequired, {
    [styles.error]: messageErr && showErrors,
  })}
>
  <Textarea
    disabled={isLoading}
    id="message"
    minRows={5}
    placeholder="Enter your message..."
    onChange={e => setMessage(e.target.value)}
    value={message}
  />
  <span className={styles.errorMsg}>Please enter your message.</span>
</div>
*/
