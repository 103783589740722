import React from 'react'

import styles from './Intro.module.scss'

const Intro = ({ children }) => {
  return <div className={styles.intro}>{children}</div>
}

const IntroTitle = ({ children }) => {
  return <h2 className={styles.introTitle}>{children}</h2>
}

const IntroText = ({ children }) => {
  return <p className={styles.introText}>{children}</p>
}

export { Intro, IntroTitle, IntroText }
